import { useRef, useState } from "react"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import Button from "../../Assets/Button/Button"
import { PlusIcon } from "../../../icons/PlusIcon"
import { CrossIcon } from "../../../icons/CrossIcon"
import SelectReference from "../../SelectReference/SelectReference"
import OrderModalBlock from "../../Modals/OrderModal/OrderModalBlock"
import styles from "./OrderServiceReference.module.scss"

const OrderServiceReference = ({
  onSubmit,
  type,
  error,
}: {
  onSubmit: (ids: string[]) => void
  type: string
  error?: boolean
}) => {
  const [isAssigneeOpen, setAssigneeOpen] = useState<boolean>(false)
  const selectUserBtnRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(selectUserBtnRef, () => {
    if (isAssigneeOpen) {
      setAssigneeOpen(false)
    }
  })

  const [data, setData] = useState<{ id: string; name: string; avatar_id?: string }[]>([])

  const handleClear = (id: string) => {
    setData((prev) => prev.filter((item) => item.id !== id))
  }

  const handleSubmit = (data: { id: string; name: string; avatar_id?: string }[]) => {
    onSubmit(data.map((item) => item.id))
    setData(data)
    setAssigneeOpen(false)
  }

  return (
    <OrderModalBlock title={`Выберите сотрудника`}>
      <div ref={selectUserBtnRef} className={styles.block}>
        {data?.length > 0 ? (
          data.map((item) => {
            return (
              <div key={item.id} className={styles.currentUser}>
                <span className={styles.currentUserName}>{item?.name}</span>
                <button type={"button"} className={styles.currentUserBtn} onClick={() => handleClear(item.id)}>
                  <CrossIcon />
                </button>
              </div>
            )
          })
        ) : (
          <Button
            type={"button"}
            txt={
              <>
                <PlusIcon />
                Добавить
              </>
            }
            mode={"light"}
            className={styles.btn}
            onClick={() => setAssigneeOpen((prev) => !prev)}
          />
        )}

        {isAssigneeOpen && <SelectReference type={type} list={data} onSubmit={(data) => handleSubmit(data)} />}
      </div>
      {error && <span>нужно указать сотрудника</span>}
    </OrderModalBlock>
  )
}

export default OrderServiceReference
