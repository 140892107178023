// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderServiceReference_block__XCA\\+z {
  position: relative;
  align-self: flex-start;
  max-width: 100%;
}

button.OrderServiceReference_btn__OOSCP {
  color: var(--text-color);
  font-weight: normal;
  height: 40px;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
button.OrderServiceReference_btn__OOSCP svg {
  color: var(--accent-color);
  flex-shrink: 0;
  margin-right: 11px;
  transition: 0.2s;
}
@media (hover: hover) {
  button.OrderServiceReference_btn__OOSCP:hover svg {
    color: var(--white);
  }
}

.OrderServiceReference_currentUser__EMn0E {
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  min-height: 38px;
  background-color: #fafafa;
  padding: 4px 10px;
}

.OrderServiceReference_currentUserName__JmpbH {
  overflow: hidden;
  text-overflow: ellipsis;
}

.OrderServiceReference_currentUserBtn__Zs\\+P9 {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  transition: var(--transition);
}
@media (hover: hover) {
  .OrderServiceReference_currentUserBtn__Zs\\+P9:hover {
    opacity: 0.5;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/OrderService/OrderServiceReference/OrderServiceReference.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,wBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,0BAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAEE;EAEI;IACE,mBAAA;EADN;AACF;;AAMA;EACE,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,6BAAA;AAHF;AAKE;EACE;IACE,YAAA;EAHJ;AACF","sourcesContent":[".block {\r\n  position: relative;\r\n  align-self: flex-start;\r\n  max-width: 100%;\r\n}\r\n\r\nbutton.btn {\r\n  color: var(--text-color);\r\n  font-weight: normal;\r\n  height: 40px;\r\n  border-radius: 8px;\r\n  padding-left: 12px;\r\n  padding-right: 12px;\r\n\r\n  svg {\r\n    color: var(--accent-color);\r\n    flex-shrink: 0;\r\n    margin-right: 11px;\r\n    transition: 0.2s;\r\n  }\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      svg {\r\n        color: var(--white);\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.currentUser {\r\n  border-radius: 8px;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  max-width: 100%;\r\n  min-height: 38px;\r\n  background-color: #fafafa;\r\n  padding: 4px 10px;\r\n}\r\n\r\n.currentUserName {\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.currentUserBtn {\r\n  width: 16px;\r\n  height: 16px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-shrink: 0;\r\n  margin-left: 10px;\r\n  transition: var(--transition);\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      opacity: 0.5;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `OrderServiceReference_block__XCA+z`,
	"btn": `OrderServiceReference_btn__OOSCP`,
	"currentUser": `OrderServiceReference_currentUser__EMn0E`,
	"currentUserName": `OrderServiceReference_currentUserName__JmpbH`,
	"currentUserBtn": `OrderServiceReference_currentUserBtn__Zs+P9`
};
export default ___CSS_LOADER_EXPORT___;
