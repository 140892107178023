import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import styles from "./CoordinationsFilters.module.scss"
import moment from "moment"
import { useLazyGetUsersQuery } from "../../../redux/api/content"
import Filter from "../../Filter/Filter"
import { IEmployee } from "../../../types/content"
import useWindowSize from "../../../hooks/useWindowSize"
import { useGetServicesMutation } from "../../../redux/api/main"
import { IOrderShort } from "../../../types/orderTypes"
import { IStatusFilter } from "../Coordinations"

interface Props {
  selectedUsers: IEmployee[]
  setSelectedUsers: Dispatch<SetStateAction<IEmployee[]>>
  selectedCategory: IOrderShort[]
  setSelectedCategory: Dispatch<SetStateAction<IOrderShort[]>>
  selectedStatuses: IStatusFilter[]
  setSelectedStatuses: Dispatch<SetStateAction<IStatusFilter[]>>
  initiationDate: number[]
  setInitiationDate: Dispatch<SetStateAction<number[]>>
}

const statusList = [
  { id: 1, name: "Ожидает согласования", value: "in_progress" },
  { id: 2, name: "Согласовано", value: "approved" },
  { id: 3, name: "Отклонено", value: "denied" },
]

const CoordinationsFilters = ({
  selectedUsers,
  setSelectedUsers,
  selectedCategory,
  setSelectedCategory,
  selectedStatuses,
  setSelectedStatuses,
  initiationDate,
  setInitiationDate,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [getUsers, { isFetching: isUsersFetching, isLoading: isUsersLoading, isUninitialized: isUsersUninitialized }] =
    useLazyGetUsersQuery()
  const [
    getCategories,
    { status: categoriesStatus, isLoading: isCategoriesLoading, isUninitialized: isCategoriesUninitialized },
  ] = useGetServicesMutation()

  return (
    <div className={styles["filters-container"]}>
      <Filter
        text={t("applicant")}
        content="members"
        selectedValues={selectedUsers}
        setChangeFilter={setSelectedUsers}
        numerals={[t("employee1"), t("employee2"), t("employee3")]}
        isSearch
        titleModal={t("selectApplicants")}
        apiGetValues={{ func: getUsers, resKey: "aUsers", endKey: "bIsEnd" }}
        isFetching={isUsersFetching}
        isUninitialized={isUsersUninitialized}
        isLoading={isUsersLoading}
        className={styles["members-filter"]}
      />
      <Filter
        text={t("service")}
        content="list"
        selectedValues={selectedCategory}
        setChangeFilter={setSelectedCategory}
        isSearch
        titleModal={t("selectServices")}
        apiGetValues={{ func: getCategories, resKey: "data.aItems", endKey: "data.bIsEnd", searchKeyStr: "keywords" }}
        isFetching={categoriesStatus !== "fulfilled"}
        isUninitialized={isCategoriesUninitialized}
        isLoading={isCategoriesLoading}
      />
      <Filter
        text={t("initiationDate")}
        content="date"
        setChangeFilter={setInitiationDate}
        selectedValues={initiationDate}
        maxDate={new Date(moment().format("YYYY-MM-DD"))}
        titleModal={t("initiationDate")}
      />
      <Filter
        text={t("approvalStatus")}
        content="list"
        value={statusList}
        selectedValues={selectedStatuses}
        setChangeFilter={setSelectedStatuses}
        titleModal={t("selectStatuses")}
      />
    </div>
  )
}

export default CoordinationsFilters
