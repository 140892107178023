import React, { useEffect, useRef, useState } from "react"
import styles from "./MultiDatePicker.module.scss"
import ReactDatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import ReactSelect, { components } from "react-select"
import { useFormik } from "formik"
import moment from "moment"
import CheckBoxRow from "../Assets/CheckBoxRow/CheckBoxRow"
import { TimeLightIcon } from "../../icons/TimeLightIcon"
import Button from "../Assets/Button/Button"
import { ArrowRightIcon } from "../../icons/ArrowRightIcon"
import clsx from "clsx"
import TextInput from "../Assets/TextInput/TextInput"
import { PlusIcon } from "../../icons/PlusIcon"

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <i className="iconfont icon-drop_down" />
  </components.DropdownIndicator>
)

interface Props {
  initialVals?: any
  onChangeMainDate?: any
  onChangeRepeatDate?: any
  onChangeRepeatType?: any
  onChangeRepeatData?: any
  positionType?: "fixed" | "absolute"
  positionTopOffset?: number
  isTwoCalendars?: boolean
  isDefaultOpen?: boolean
  maxDate?: Date
  withTime?: boolean
  pickerIsRange?: boolean
}

const MultiDatePicker: React.FC<Props> = ({
  initialVals = {},
  onChangeMainDate = () => null,
  onChangeRepeatDate = () => null,
  onChangeRepeatType = () => null,
  onChangeRepeatData = () => null,
  positionType = "fixed",
  positionTopOffset = 0,
  isTwoCalendars = false,
  isDefaultOpen = false,
  maxDate = undefined,
  withTime = false,
  pickerIsRange = false,
}) => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: `interface` })
  // Открытие\закрытие пикера
  const [isOpen, setIsOpen] = useState(isDefaultOpen)
  // Смехения по Y и X
  const [offsetY, setOffsetY] = useState(0)
  const [offsetX, setOffsetX] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [pickerVisible, setPickerVisible] = useState(false)
  const wpRef = useRef<any>(null)
  const pickerRef = useRef<any>(null)

  // Флаг не первого рендера
  const noFirstRender = useRef(false)

  // Варианты повтора
  const recycleVars = [
    { label: t("Ежедневно"), value: "daily" },
    { label: t("Еженедельно"), value: "weekly" },
    { label: t("Ежемесячно"), value: "monthly" },
    { label: t("Ежегодно"), value: "annually" },
    { label: t("Периодически"), value: "periodically" },
  ]
  // Дни недели
  const weekDays = [
    { label: t("Пн"), value: 0 },
    { label: t("Вт"), value: 1 },
    { label: t("Ср"), value: 2 },
    { label: t("Чт"), value: 3 },
    { label: t("Пт"), value: 4 },
    { label: t("Сб"), value: 5 },
    { label: t("Вс"), value: 6 },
  ]
  // Номер периода
  const weekDayCount = [
    { label: t("1-й"), value: 0 },
    { label: t("2-й"), value: 1 },
    { label: t("3-й"), value: 2 },
    { label: t("4-й"), value: 3 },
    { label: t("Последний"), value: 4 },
  ]
  // Выбор временного интервала при периодическом повторе
  const periodTypes = [
    { label: t("Час"), value: "hour", count: 12 },
    { label: t("День"), value: "day", count: 14 },
    { label: t("Неделя"), value: "week", count: 24 },
    { label: t("Месяц"), value: "month", count: 6 },
    { label: t("Квартал"), value: "quarter", count: 4 },
    { label: t("Полугодие"), value: "halfyear", count: 2 },
    { label: t("Год"), value: "year", count: 6 },
  ]
  // Выбор дня
  const daysSelect: any[] = []
  for (let index = 0; index < 28; index++) {
    daysSelect.push({ label: index + 1, value: index + 1 })
  }
  daysSelect.push({ label: t("Последний"), value: 29 })
  //

  const initialTime1 = initialVals?.planed_start_date
      ? moment(initialVals?.planed_start_date * 1000).format("HH:mm")
      : "", // Время начала
    initialTime2 = initialVals?.planed_end_date ? moment(initialVals?.planed_end_date * 1000).format("HH:mm") : "" // Время окончания

  const initialValues = {
    pickerDateRange: [initialVals?.planed_start_date ?? undefined, initialVals?.planed_end_date ?? undefined], // интервал дат в календаре
    pickerDateSingle:
      initialVals?.planed_end_date &&
      initialVals?.planed_start_date &&
      initialVals?.planed_end_date === initialVals?.planed_start_date
        ? initialVals?.planed_start_date
        : null, // дата когда не выбран интервад
    pickerTime: [initialTime1, initialTime2], //Время в пикере
    canSelectStartDate:
      (initialVals?.planed_end_date && initialVals?.planed_start_date !== initialVals?.planed_end_date) ||
      (initialVals?.planed_start_date && !initialVals?.planed_end_date) ||
      !!pickerIsRange, // Показывать ли инпут выбора второй даты в периоде
    canSelectStartTime: !!initialTime1 && initialVals?.planed_start_date !== initialVals?.planed_end_date, // Показывать ли инпут выбора времени начала
    selectTime: !!initialTime1 || !!initialTime2, // Выбор (показ) инпутов времени
    selectRecyclin: !!initialVals?.repeat_type, //Включение повтора
    selectEndDate: false, // Показ второго селекта периода
    endDate: initialVals?.repeat_stop ?? null, // Дата окончания
    repeatVal: initialVals?.repeat_type
      ? recycleVars.find((el) => el.value === initialVals?.repeat_type) ?? recycleVars[0]
      : recycleVars[0], // Выбор типа повтора
    endType: initialVals?.repeat_stop ? "date" : "never", // Тип окончания
    selectedWeekDays: initialVals?.repeat_data?.weekDays ?? [], //Выбранные дни недели при ежедневном
    monthlyRepeatType: initialVals?.repeat_data?.monthlyRepeatType ?? "every", // Тип повтора при ежемесячном
    monthlyWeekDay: initialVals?.repeat_data?.monthlyWeekDay
      ? weekDays.find((el) => el.value === initialVals?.repeat_data?.monthlyWeekDay)
      : weekDays[0], // День недели при ежемесячном
    monthlyDayCount: initialVals?.repeat_data?.monthlyDayCount
      ? weekDayCount.find((el) => el.value === initialVals?.repeat_data?.monthlyDayCount)
      : weekDayCount[0], // Какой по счёту день недели при ежемесячном
    monthlyDaySingle: initialVals?.repeat_data?.monthlyDaySingle
      ? daysSelect.find((el) => el.value === initialVals?.repeat_data?.monthlyDaySingle)
      : daysSelect[0], // Выбор конкретного дня при ежемесячном
    periodType: initialVals?.repeat_data?.periodType
      ? periodTypes.find((el) => el.value === initialVals?.repeat_data?.periodType)
      : periodTypes[0], // Выбранный тип периода при периодическом повторе
    periodTypeCount: initialVals?.repeat_data?.periodTypeCount ?? { label: 1, value: 1 }, // Выбранное значение при периодическом повторе
  }
  const pickForm = useFormik<any>({
    initialValues,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values, formikHelpers) => {},
  })

  const { values, setFieldValue } = pickForm

  useEffect(() => {
    if (noFirstRender?.current && isOpen) {
      setFieldValue("periodTypeCount", { label: 1, value: 1 })
    }
  }, [values?.periodType])

  // Наполнение селекта при периодическом повторе
  const periodTypeCounts = []
  for (let index = 1; index <= values?.periodType?.count ?? 12; index++) {
    periodTypeCounts.push({ label: index, value: index })
  }
  // Очистка пикера
  const clearPicker = () => {
    pickForm.resetForm({
      values: {
        pickerDateRange: [],
        pickerDateSingle: null,
        pickerTime: ["", ""],
        canSelectStartDate: pickerIsRange,
        canSelectStartTime: false,
        selectTime: false,
        selectRecyclin: false,
        selectEndDate: false,
        endDate: null,
        repeatVal: recycleVars[0],
        endType: "never",
        selectedWeekDays: [],
        monthlyRepeatType: "every",
        monthlyWeekDay: weekDays[0],
        monthlyDayCount: weekDayCount[0],
        monthlyDaySingle: daysSelect[0],
        periodType: periodTypes[0],
        periodTypeCount: { label: 1, value: 1 },
      },
    })
    onChangeRepeatData(null)
    onChangeMainDate([null, null])
    onChangeRepeatDate(null)
    onChangeRepeatType(null)
  }

  // Чтоб при первом рендере не срабатывали юсЭффекты изменения значений
  useEffect(() => {
    setTimeout(() => {
      noFirstRender.current = true
    }, 100)
  }, [])

  useEffect(() => {
    // Смещение блока если в экран не влазит

    if (isOpen && wpRef?.current) {
      const blockPosition = wpRef.current.getBoundingClientRect()
      if (positionType === "absolute") {
        // Абсолютное позиционирование мб не работает
        if (blockPosition.top + blockPosition.height > document.documentElement.clientHeight - 105) {
          const num = blockPosition.top + blockPosition.height - document.documentElement.clientHeight - 105
          offsetY !== num
            ? setOffsetY(document.documentElement.clientHeight - 80 - (blockPosition.top + blockPosition.height))
            : null
        } else {
          Number(offsetY) !== 0 ? setOffsetY(0) : null
        }
        if (blockPosition.left + blockPosition.width > document.documentElement.clientWidth - 50) {
          const num = blockPosition.left + blockPosition.width - document.documentElement.clientWidth - 10
          offsetX !== num
            ? setOffsetX(document.documentElement.clientWidth - 8 - (blockPosition.left + blockPosition.width))
            : null
        } else {
          Number(offsetX) !== 0 ? setOffsetX(0) : null
        }
      } else {
        // Фиксированное позиционирование
        setOffsetX(blockPosition.left)
      }
      // inputRef?.current?.focus()
    } else {
      setOffsetY(0)
      // setOffsetX(0)
      setFieldValue("selectEndDate", false)
    }
    // Показывать сам пикер когда уже определится позиция
    setTimeout(() => {
      setPickerVisible(isOpen)
    }, 1)
  }, [isOpen])

  useEffect(() => {
    // Отслеживание изменения размера блока пикера, установка макс высоты при необходимости
    if (isOpen) {
      const resizeObserver = new ResizeObserver((e) => {
        const gbcr = pickerRef?.current?.getBoundingClientRect()
        const pickHeight = gbcr?.height ?? 0
        const pickTop = gbcr?.top ?? 0
        if (pickHeight + pickTop > document.documentElement.clientHeight) {
          const difference = pickHeight + pickTop - document.documentElement.clientHeight
          setMaxHeight(pickHeight - difference - 25)
        }
      })
      resizeObserver?.observe(pickerRef?.current)
      return function cleanup() {
        setMaxHeight(0)
        resizeObserver.disconnect()
      }
    }
  }, [isOpen])

  useEffect(() => {
    // Дополнение основной даты в unix временем в секундах с инпутов
    const formDate = isRange ? values.pickerDateRange : values.pickerDateRange?.[0],
      date = isRange
        ? [
            moment(formDate?.[0] * 1000).format("YYYY-MM-DD"),
            formDate?.[1] ? moment(formDate?.[1] * 1000).format("YYYY-MM-DD") : 0,
          ]
        : moment(formDate * 1000).format("YYYY-MM-DD")

    if (((isRange && date?.[0]) || (!isRange && date)) && date !== "Invalid date" && isOpen) {
      const time0 = values.selectTime ? values?.pickerTime?.[0] : "",
        time1 = values.selectTime ? values?.pickerTime?.[1] : ""

      if (isRange) {
        setFieldValue("pickerDateRange", [
          moment(`${moment(date[0]).format("YYYY-MM-DD")} ${time0}`).unix(),
          date?.[1] ? moment(`${moment(date[1]).format("YYYY-MM-DD")} ${time1}`).unix() : null,
        ])
      } else {
        const dateEl0 = moment(`${moment(date).format("YYYY-MM-DD")} ${time0}`).unix()
        const dateEl1 = moment(`${moment(date).format("YYYY-MM-DD")} ${time1}`).unix()
        setFieldValue("pickerDateSingle", dateEl1)
        setFieldValue("pickerDateRange", [time0 && values.canSelectStartTime ? dateEl0 : dateEl1, dateEl1])
      }
    }
  }, [values?.pickerTime, values?.selectTime])

  const changeMainDate = () => {
    if (noFirstRender?.current && isOpen) {
      onChangeMainDate(values.pickerDateRange)
    }
  }

  // Запись даты повтора периода
  useEffect(() => {
    if (noFirstRender?.current && isOpen) {
      const setedData = values?.selectRecyclin && values?.endDate ? values.endDate : null
      onChangeRepeatDate(setedData)
    }
  }, [values.endDate, values.selectRecyclin])

  // Запись типа повтора
  useEffect(() => {
    if (noFirstRender?.current && isOpen) {
      const setedData = values?.selectRecyclin && values?.repeatVal?.value ? values?.repeatVal?.value : null
      onChangeRepeatType(setedData)
    }
  }, [values.repeatVal, values.selectRecyclin])

  // Запись параметров повтора
  useEffect(() => {
    if (noFirstRender?.current && isOpen) {
      if (!values.selectRecyclin) onChangeRepeatType(null)
      else {
        if (values.repeatVal?.value === "weekly") {
          onChangeRepeatData({
            weekDays: values?.selectedWeekDays ?? [],
          })
        }
        if (values.repeatVal?.value === "monthly") {
          const appData =
            values?.monthlyRepeatType === "specificDay"
              ? { monthlyDaySingle: values.monthlyDaySingle?.value }
              : {
                  monthlyDayCount: values.monthlyDayCount?.value,
                  monthlyWeekDay: values.monthlyWeekDay?.value,
                }
          onChangeRepeatData({
            monthlyRepeatType: values?.monthlyRepeatType ?? "every",
            ...appData,
          })
        }
        if (values.repeatVal?.value === "periodically") {
          switch (values.periodType?.value) {
            case "hour":
            case "day":
            case "quarter":
            case "halfyear":
            case "year":
              onChangeRepeatData({
                periodType: values?.periodType?.value ?? "hour",
                periodTypeCount: values?.periodTypeCount ?? { label: 1, value: 1 },
              })
              break
            case "week":
              onChangeRepeatData({
                periodType: values?.periodType?.value ?? "week",
                periodTypeCount: values?.periodTypeCount ?? { label: 1, value: 1 },
                weekDays: values?.selectedWeekDays ?? [],
              })
              break
            case "month":
              // eslint-disable-next-line no-case-declarations
              const appData =
                values?.monthlyRepeatType === "specificDay"
                  ? { monthlyDaySingle: values.monthlyDaySingle?.value }
                  : {
                      monthlyDayCount: values.monthlyDayCount?.value,
                      monthlyWeekDay: values.monthlyWeekDay?.value,
                    }

              onChangeRepeatData({
                periodType: values?.periodType?.value ?? "month",
                periodTypeCount: values?.periodTypeCount ?? { label: 1, value: 1 },
                monthlyRepeatType: values?.monthlyRepeatType ?? "every",
                ...appData,
              })
              break
            default:
              break
          }
        }
      }
    }
  }, [
    values.repeatVal,
    values.selectRecyclin,
    values.selectedWeekDays,
    values?.periodType,
    values?.periodTypeCount,
    values?.monthlyRepeatType,
    values.monthlyDaySingle,
    values.monthlyDayCount,
    values.monthlyWeekDay,
  ])

  // Количество необходимых дней недели в месяце, возвращает их даты
  const getDaysOfWeekInMonth = (year: number, month: number, weekDayCount: number) => {
    const daysInMonth = moment(`${year}-${month}-1`).daysInMonth()
    const foundedDays = []
    for (let index = 1; index <= daysInMonth; index++) {
      const wd = Number(moment(`${year}-${month}-${index}`).format("E")) //.day() - 0-6 начиная с вс, Е - 1-7 с пн
      if (wd === weekDayCount) foundedDays.push(index)
    }

    return foundedDays
  }

  // Рендер дней с логикой отображения класса выбранности
  const renderDayContents = (day: any, date: any) => {
    const classNames = "choosed"
    let classSet = false
    const unlimited = values.endType === "never"

    // Если включен повтор
    if (values.selectRecyclin) {
      // Дата в конкретном блоке
      const thisDate = moment(date) // Дата
      const thisDateSec = thisDate.unix() // Дата в секундах
      const thisDateWD = moment(thisDate).format("e") // День недели
      // Минимальная дата
      const minDateM = moment(values.pickerDateRange[0] * 1000)
      const minDate = moment(minDateM.format("YYYY-MM-DD")).unix()
      // Максимальная дата
      const maxDate = values.endDate
      // Значение типа повтора
      const rv = values?.repeatVal?.value
      if (
        rv === "daily" &&
        minDate &&
        thisDateSec >= minDate &&
        ((maxDate && thisDateSec <= maxDate) || unlimited || !maxDate)
      ) {
        classSet = true
      } else if (
        rv === "weekly" &&
        values.selectedWeekDays?.includes(Number(thisDateWD)) &&
        minDate &&
        thisDateSec >= minDate &&
        ((maxDate && thisDateSec <= maxDate) || unlimited || !maxDate)
      ) {
        classSet = true
      } else if (
        rv === "monthly" &&
        minDate &&
        thisDateSec >= minDate &&
        ((maxDate && thisDateSec <= maxDate) || unlimited || !maxDate)
      ) {
        // Если конкретный день в списке - то выводить его, если выбран последний - то последний
        const sigDay = values.monthlyDaySingle?.value <= 28 ? values.monthlyDaySingle?.value : thisDate.daysInMonth()
        // Конкретный день в ежемесячно
        if (values.monthlyRepeatType === "specificDay" && Number(thisDate.format("DD")) === sigDay) {
          classSet = true
        } else if (values.monthlyRepeatType === "every") {
          // Выбранный день недели
          const selDey = values.monthlyWeekDay?.value,
            selCountDay = values.monthlyDayCount?.value // Выбранный номер по счёту

          // Список дат нужных дней в месяце
          const ndim = getDaysOfWeekInMonth(Number(thisDate.format("YYYY")), Number(thisDate.format("MM")), selDey + 1)

          if (ndim[selCountDay === 4 ? ndim?.length - 1 : selCountDay] === Number(thisDate.format("DD"))) {
            classSet = true
          }
        }
      } else if (
        rv === "annually" &&
        minDate &&
        thisDateSec >= minDate &&
        ((maxDate && thisDateSec <= maxDate) || !unlimited || !maxDate)
      ) {
        const dayS = Number(moment(minDate * 1000).format("D")) // День начала
        const monthS = Number(moment(minDate * 1000).format("M")) // Месяц начала
        const dateMonth = moment(date).get("month") + 1 // Месяц текущей даты
        if (dayS === day && monthS === dateMonth) classSet = true
      } else if (
        rv === "periodically" &&
        minDate &&
        thisDateSec >= minDate &&
        ((maxDate && thisDateSec <= maxDate) || !unlimited || !maxDate)
      ) {
        const type = values.periodType?.value,
          count = values.periodTypeCount?.value,
          secInDay = 3600 * 24
        if (type === "day") {
          const secInCount = secInDay * count //Дни в секундах
          const devide = thisDateSec - minDate
          // Если нет остатка от деления, то выводить дату
          if (!(devide % secInCount)) {
            classSet = true
          }
        } else if (type === "week") {
          const startDateMinWeek = Number(minDateM.format("E")), // день недели начала интервала
            secInWeek = secInDay * 7, // секунд в неделе
            startMinDate = minDate - (startDateMinWeek - 1) * secInDay,
            startThisDate = thisDateSec - (Number(thisDate.format("E")) - 1) * secInDay,
            secInWeekCount = secInWeek * count, // Количество недель между
            devide = startThisDate - startMinDate

          if (!(devide % secInWeekCount) && values.selectedWeekDays?.includes(Number(thisDate.format("E")) - 1)) {
            classSet = true
          }
        } else if (type === "month") {
          const startMonthDay = moment(`${minDateM.get("y")}-${minDateM.get("month") + 1}-1`), //Начало месяца
            toDateDiffMonths = thisDate.diff(startMonthDay, "month"), // Разница месяцев между началом стартового и текущим
            targetMonths = [] // Месяца которые будут выводиться исходя порядка

          for (let index = 0; index <= toDateDiffMonths; index += count) {
            targetMonths.push(index) // Добавление в список искомых месяцев
          }

          // Если текущий месяц есть в списке целевых
          if (targetMonths.includes(toDateDiffMonths)) {
            // Если конкретный день в списке - то выводить его, если выбран последний - то последний
            const sigDay =
              values.monthlyDaySingle?.value <= 28 ? values.monthlyDaySingle?.value : thisDate.daysInMonth()
            if (values.monthlyRepeatType === "specificDay" && thisDate.get("date") === sigDay) {
              classSet = true
            } else if (values.monthlyRepeatType === "every") {
              // Выбранный день недели
              const selDey = values.monthlyWeekDay?.value,
                selCountDay = values.monthlyDayCount?.value // Выбранный номер по счёту

              // Список дат нужных дней в месяце
              const ndim = getDaysOfWeekInMonth(
                Number(thisDate.format("YYYY")),
                Number(thisDate.format("MM")),
                selDey + 1,
              )

              if (ndim[selCountDay === 4 ? ndim?.length - 1 : selCountDay] === Number(thisDate.format("DD"))) {
                classSet = true
              }
            }
          }
        } else if (type === "quarter" || type === "halfyear" || type === "year") {
          const startMonthDay = moment(`${minDateM.get("y")}-${minDateM.get("month") + 1}-1`), //Начало месяца
            toDateDiffMonths = thisDate.diff(startMonthDay, "month"), // Разница месяцев между началом стартового и текущим
            targetMonths = [], // Месяца которые будут выводиться исходя порядка
            multiCount = type === "quarter" ? 3 : type === "halfyear" ? 6 : 12 // Домножение количества месяцев в зависимости от квартал\полугодие\год
          //  x3 т.к в квартале 3 месяца
          for (let index = 0; index <= toDateDiffMonths; index += count * multiCount) {
            targetMonths.push(index) // Добавление в список искомых месяцев
          }
          const sigDay = minDateM.get("date")
          // Если текущий месяц есть в списке целевых
          if (targetMonths.includes(toDateDiffMonths) && thisDate.get("date") === sigDay) {
            classSet = true
          }
        }
      }
    }

    return <span className={clsx(classSet ? classNames : "", "custom-day")}>{day}</span>
  }

  // Возможность выбора интервала
  const isRange = values.canSelectStartDate

  // Текст для блока выбранного повтора
  const getRepeatText = () => {
    const rTypeV = values?.repeatVal?.value
    let addedText = ""

    if (rTypeV === "weekly") {
      const wd = values?.selectedWeekDays.map((dd: number) => weekDays[dd].label)
      addedText = `${wd.length ? t("to3") : ""} ${wd.join(", ")}`
    } else if (rTypeV === "monthly") {
      if (values.monthlyRepeatType === "every") {
        addedText = `${t("каждый")} ${values?.monthlyDayCount?.label} ${values?.monthlyWeekDay?.label}`
      } else if (values.monthlyRepeatType === "specificDay") {
        addedText = `${t("каждый")} ${values?.monthlyDaySingle?.label} ${t("day1")}`
      }
    } else if (rTypeV === "periodically") {
      switch (values.periodType?.value) {
        case "hour":
          addedText = `${t("каждый")} ${values?.periodTypeCount?.label} ${t("hour1")}`
          break
        case "day":
          addedText = `${t("каждый")} ${values?.periodTypeCount?.label} ${t("day1")}`
          break
        case "week":
          // eslint-disable-next-line no-case-declarations
          const wd = values?.selectedWeekDays.map((dd: number) => weekDays[dd].label)
          addedText = `${t("каждую")} ${values?.periodTypeCount?.label} ${t("неделю")} ${wd.join(", ")}`
          break
        case "month":
          addedText =
            `${t("каждый")} ${values?.periodTypeCount?.label} ${t("Месяц")} ` +
            (values.monthlyRepeatType === "specificDay"
              ? `${values?.monthlyDaySingle?.label} ${t("day1")}`
              : `${values?.monthlyDayCount?.label} ${values?.monthlyWeekDay?.label}`)
          break
        case "quarter":
          addedText = `${t("каждый")} ${values?.periodTypeCount?.label} ${t("Квартал")}`
          break
        case "halfyear":
          addedText = `${t("каждое")} ${values?.periodTypeCount?.label} ${t("Полугодие")}`
          break
        case "year":
          addedText = `${t("каждый")} ${values?.periodTypeCount?.label} ${t("год")}`
          break

        default:
      }
    }
    return !rTypeV || !addedText ? <></> : <span>&nbsp;{`${addedText}`}&nbsp;</span>
  }

  const Picker = () => {
    return (
      <ReactDatePicker
        id={"orderpicker"}
        locale={i18n.language}
        selected={
          isRange
            ? !values?.pickerDateRange[0]
              ? new Date(moment().subtract("month", 1).format("YYYY-MM-DD HH:mm"))
              : new Date(moment.unix(values?.pickerDateRange[0]).format("YYYY-MM-DD HH:mm"))
            : values?.pickerDateSingle
            ? new Date(moment(values.pickerDateSingle * 1000).format("YYYY-MM-DD HH:mm"))
            : null
        }
        startDate={
          !isRange
            ? undefined
            : values?.pickerDateRange?.[0]
            ? new Date(moment(values?.pickerDateRange?.[0] * 1000).format("YYYY-MM-DD HH:mm"))
            : undefined
        }
        endDate={
          !isRange
            ? undefined
            : values?.pickerDateRange?.[1]
            ? new Date(moment(values?.pickerDateRange?.[1] * 1000).format("YYYY-MM-DD HH:mm"))
            : undefined
        }
        maxDate={maxDate}
        selectsRange={isRange}
        onChange={(date: any, month: any) => {
          if (isRange) {
            setFieldValue("pickerDateRange", [
              moment(`${moment(date[0]).format("YYYY-MM-DD")} ${values.pickerTime[0]}`).unix(),
              date?.[1] ? moment(`${moment(date[1]).format("YYYY-MM-DD")} ${values.pickerTime[1]}`).unix() : null,
            ])
            onChangeMainDate([
              moment(`${moment(date[0]).format("YYYY-MM-DD")} ${values.pickerTime[0]}`).unix(),
              date?.[1] ? moment(`${moment(date[1]).format("YYYY-MM-DD")} ${values.pickerTime[1]}`).unix() : null,
            ])
          } else {
            const time0 = values.selectTime ? values?.pickerTime?.[0] : "",
              time1 = values.selectTime ? values?.pickerTime?.[1] : "",
              dateEl0 = moment(`${moment(date).format("YYYY-MM-DD")} ${time0}`).unix(),
              dateEl1 = moment(`${moment(date).format("YYYY-MM-DD")} ${time1}`).unix()

            setFieldValue("pickerDateSingle", dateEl1)
            setFieldValue("pickerDateRange", [time0 && values.canSelectStartTime ? dateEl0 : dateEl1, dateEl1])
          }

          if (values.selectTime && !values.pickerTime?.[1]) {
            setFieldValue("pickerTime[1]", moment().format("HH:mm"))
          }
        }}
        inline
        renderDayContents={renderDayContents}
        swapRange
        calendarClassName={clsx(styles["calendar"], isTwoCalendars && styles["double-calendar"])}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          customHeaderCount,
          monthDate,
        }) => {
          return (
            <div className={styles["calendar__header"]}>
              <div className={styles["month-year-block"]}>
                <span className="react-datepicker__current-month">{moment(monthDate).format("MMMM YYYY")}</span>
              </div>

              {customHeaderCount === 0 && (
                <Button
                  txt={""}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  icon={<ArrowRightIcon />}
                  className={clsx(
                    styles["change-mounth__btn"],
                    prevMonthButtonDisabled && styles["change-mounth__btn__disabled"],
                    styles["change-mounth__btn__prev"],
                  )}
                />
              )}

              {(isTwoCalendars ? customHeaderCount === 1 : customHeaderCount === 0) && (
                <Button
                  txt={""}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  icon={<ArrowRightIcon />}
                  className={clsx(
                    styles["change-mounth__btn"],
                    nextMonthButtonDisabled && styles["change-mounth__btn__disabled"],
                  )}
                />
              )}
            </div>
          )
        }}
        monthsShown={isTwoCalendars ? 2 : 1}
      />
    )
  }

  return (
    <div className={clsx(styles.multiPicker__wp, "multiPicker__wp")} ref={wpRef}>
      {values?.pickerDateRange?.[0] ? (
        <>
          {/* <div className={styles.multiPicker__selectRange} onClick={() => setIsOpen((pr) => !pr)}>
            {`getFontIcon("picker-input-icon")`} {moment(values?.pickerDateRange?.[0] * 1000).format("D MMMM")}{" "}
            {values?.pickerTime?.[isRange ? 0 : 1]
              ? moment(values?.pickerDateRange?.[isRange ? 0 : 1] * 1000).format("HH:mm")
              : ""}{" "}
            {values?.pickerDateRange?.[1] && values?.pickerDateRange?.[0] !== values?.pickerDateRange?.[1]
              ? `- ${moment(values?.pickerDateRange?.[1] * 1000).format("D MMMM")} ${
                  values?.pickerTime?.[1] ? moment(values?.pickerDateRange?.[1] * 1000).format("HH:mm") : ""
                }`
              : ""}
          </div> */}
          {values?.selectRecyclin && (
            <div className={styles.multiPicker__selectRecyling} onClick={() => setIsOpen((pr) => !pr)}>
              {`getFontIcon("recycling-icon")`} {values?.repeatVal?.label ?? ""} {getRepeatText()}
              {values?.endDate && values?.endType === "date"
                ? `${t("to")} ${moment(values?.endDate * 1000).format("DD.MM.YYYY")}`
                : ""}
            </div>
          )}
        </>
      ) : (
        <div className={`btnAdd`} onClick={() => setIsOpen((pr) => !pr)}>
          <i className={"iconfont icon-close_round texticon"}></i>
        </div>
      )}
      {isOpen && (
        // <OutsideClickHandler
        //   onOutsideClick={() => {
        //     setIsOpen(false)
        //   }}
        // >
        <div
          onClick={() => {
            // onOutsideClick={() => {
            setIsOpen((prev) => true)
            // }}
          }}
        >
          <div
            className={`${styles.multiPicker} ${maxHeight ? styles.multiPicker_withMaxHeight : ""} multiPicker`}
            style={
              positionType === "fixed"
                ? {
                    top: positionTopOffset ? `${positionTopOffset}px` : "",
                    left: offsetX ? `${offsetX}px` : "",
                    position: positionType,
                    display: pickerVisible ? "" : "none",
                    maxHeight: maxHeight ? maxHeight : "",
                  }
                : {
                    top: offsetY ? `${offsetY}px` : "",
                    left: offsetX ? `${offsetX}px` : "",
                    display: pickerVisible ? "" : "none",
                    maxHeight: maxHeight ? maxHeight : "",
                  }
            }
            ref={pickerRef}
          >
            {!values?.selectEndDate && (
              <div className={clsx(styles.multiPicker__selected, isRange && styles.multiPicker__selected__range)}>
                {
                  isRange && (
                    // <TextInput
                    //   value={
                    //     values?.pickerDateRange?.[0]
                    //       ? moment(Number(values?.pickerDateRange?.[0] * 1000)).format("DD.MM.YYYY")
                    //       : ""
                    //   }
                    //   onChange={(vl: any, event: any) => {
                    //     // setFieldValue("endDate", vl)
                    //   }}
                    //   customClear={() => setFieldValue("pickerDateRange[0]", null)}
                    //   isClear
                    //   // onClick={()=>setFieldValue("selectEndDate", true)}
                    //   placeholder={t("start")}
                    // />
                    <TextInput
                      value={
                        values?.pickerDateRange?.[0]
                          ? moment(Number(values?.pickerDateRange?.[0] * 1000)).format("DD.MM.YYYY")
                          : ""
                      }
                      onChange={(vl: any) => {
                        setFieldValue("pickerDateRange[0]", vl)
                      }}
                      customClear={() => setFieldValue("pickerDateRange", [null, null])}
                      // () => setFieldValue("canSelectStartDate", true)
                      placeholder={t("filterDateStart")}
                      icon={<img className={styles["fake-input__icon"]} src="/img/picker.svg" alt="" />}
                    />
                  )
                  // : (
                  // <input
                  //   value={
                  //     values?.pickerDateRange?.[0]
                  //       ? moment(Number(values?.pickerDateRange?.[0] * 1000)).format("DD.MM.YYYY")
                  //       : ""
                  //   }
                  //   onChange={(vl: any) => {
                  //     setFieldValue("pickerDateRange[0]", vl)
                  //   }}
                  //   placeholder={t("start")}
                  // />
                  //   {isTwoCalendars && }
                  //   <div
                  //     className={styles.multiPicker__preSlected}
                  //     onClick={() => setFieldValue("canSelectStartDate", true)}
                  //   >
                  //     {`getFontIcon("add-plus-ico")`} {t("Интервал")}
                  //   </div>
                  // )
                }

                <TextInput
                  value={
                    isRange
                      ? values?.pickerDateRange?.[1]
                        ? moment(values?.pickerDateRange?.[1] * 1000).format("DD.MM.YYYY")
                        : ""
                      : values?.pickerDateSingle
                      ? moment(values?.pickerDateSingle * 1000).format("DD.MM.YYYY")
                      : ""
                  }
                  customClear={() => {
                    if (isRange) {
                      setFieldValue(`pickerDateRange[1]`, null)
                    } else {
                      setFieldValue(`pickerDateRange`, [null, null])
                      setFieldValue("pickerDateSingle", null)
                    }
                  }}
                  onChange={(vl: any) => {
                    setFieldValue(isRange ? "pickerDateRange[1]" : "pickerDateSingle", vl)
                  }}
                  placeholder={isRange ? t("filterDateEnd") : t("selectDate")}
                  icon={<img className={styles["fake-input__icon"]} src="/img/picker.svg" alt="" />}
                />

                {withTime && values?.selectTime && (
                  <>
                    {values.canSelectStartTime ? (
                      <TextInput
                        value={values?.pickerTime?.[0] ?? ""}
                        type={"time"}
                        onChange={(vl: any) => {
                          setFieldValue("pickerTime[0]", vl)
                        }}
                        placeholder={isRange ? t("filters.dateEnd") : t("filters.closedAt")}
                      />
                    ) : (
                      <div
                        className={styles.multiPicker__preSlected}
                        onClick={() => {
                          setFieldValue("canSelectStartTime", true)
                          if (!values.pickerTime?.[0]) setFieldValue("pickerTime[0]", moment().format("HH:mm"))
                        }}
                      >
                        <PlusIcon />
                        {t("startTime")}
                      </div>
                    )}

                    <TextInput
                      value={values?.pickerTime?.[1] ?? ""}
                      type={"time"}
                      onChange={(vl: any) => {
                        setFieldValue("pickerTime[1]", vl)
                      }}
                      placeholder={isRange ? t("filters.dateEnd") : t("filters.closedAt")}
                    />
                  </>
                )}
              </div>
            )}
            <div className={styles.multiPicker__picker}>
              {!values?.selectEndDate ? (
                <div className={styles.multiPicker__pickerMain}>
                  <Picker />

                  {values?.selectRecyclin && (
                    <div className={styles.multiPicker__recyclin}>
                      <div className={styles.multiPicker__recyclinRow}>
                        <div className={styles.multiPicker__rowLabel}>{t("Повторять")}</div>
                        <div className={styles.multiPicker__select}>
                          <ReactSelect
                            className={`periodSelect`}
                            classNamePrefix="periodSelect"
                            isSearchable={false}
                            name={"selectCond"}
                            value={values?.repeatVal}
                            options={recycleVars}
                            onChange={(action: any) => {
                              setFieldValue(`repeatVal`, action)
                            }}
                            placeholder={""}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator,
                            }}
                            // menuIsOpen
                          />
                        </div>
                      </div>
                      {values?.repeatVal?.value === "periodically" && (
                        <div className={styles.multiPicker__recyclinEnd}>
                          <div className={styles.multiPicker__recyclinBlock}>
                            <div className={styles.multiPicker__recyclinRow}>
                              <div className={styles.multiPicker__rowLabel}>{t("Каждый")}</div>
                              <div className={styles.multiPicker__recyclinRowSelects}>
                                <ReactSelect
                                  className={`periodSelect`}
                                  classNamePrefix="periodSelect"
                                  isSearchable={false}
                                  name={"timeInterval"}
                                  value={values?.periodTypeCount}
                                  options={periodTypeCounts}
                                  onChange={(action: any) => {
                                    setFieldValue(`periodTypeCount`, action)
                                  }}
                                  placeholder={""}
                                  components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                  }}
                                  // menuIsOpen
                                />
                                <ReactSelect
                                  className={`periodSelect`}
                                  classNamePrefix="periodSelect"
                                  isSearchable={false}
                                  name={"timeType"}
                                  value={values?.periodType}
                                  options={periodTypes}
                                  onChange={(action: any) => {
                                    setFieldValue(`periodType`, action)
                                  }}
                                  placeholder={""}
                                  components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                  }}
                                  // menuIsOpen
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(values?.repeatVal?.value === "weekly" ||
                        (values.periodType?.value === "week" && values?.repeatVal?.value === "periodically")) && (
                        <div className={styles.multiPicker__daysRow}>
                          {weekDays.map((day: any, kk: number) => {
                            const isSelect = values?.selectedWeekDays?.includes(day.value)

                            return (
                              <button
                                type="button"
                                className={`${styles.multiPicker__dayBtn} ${isSelect ? styles.selectedDay : ""}`}
                                key={kk}
                                onClick={() =>
                                  isSelect
                                    ? setFieldValue(
                                        "selectedWeekDays",
                                        values?.selectedWeekDays?.filter((el: any) => el !== day.value),
                                      )
                                    : setFieldValue("selectedWeekDays", [
                                        ...(values?.selectedWeekDays ?? []),
                                        day.value,
                                      ])
                                }
                              >
                                {day.label}
                              </button>
                            )
                          })}
                        </div>
                      )}
                      {/* {(values?.repeatVal?.value === "monthly" ||
                        (values.periodType?.value === "month" && values?.repeatVal?.value === "periodically")) && (
                        <div className={styles.multiPicker__recyclinEnd}>
                          <div className={styles.multiPicker__recyclinBlock}>
                            <div className={styles.multiPicker__recyclinRow}>
                              <Checkbox
                                name={"monthlyRepeat"}
                                value={"every"}
                                className={styles.checkbox}
                                checked={values.monthlyRepeatType === "every"}
                                onChange={(e: any) => {
                                  setFieldValue("monthlyRepeatType", "every")
                                }}
                                text={t("Каждый")}
                                isRadio
                              />
                              <CheckBoxRow
                                name={"monthlyRepeat"}
                                value={"every"}
                                className={styles.checkbox}
                                checked={values.monthlyRepeatType === "every"}
                                onChange={(e: any) => {
                                  setFieldValue("monthlyRepeatType", "every")
                                }}
                              />
                              <div className={styles.multiPicker__recyclinRowSelects}>
                                <ReactSelect
                                  className={`periodSelect`}
                                  classNamePrefix="periodSelect"
                                  isSearchable={false}
                                  name={"selectPer"}
                                  value={values?.monthlyDayCount}
                                  options={weekDayCount}
                                  onChange={(action: any) => {
                                    setFieldValue(`monthlyDayCount`, action)
                                  }}
                                  placeholder={""}
                                  components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                  }}
                                  isDisabled={values.monthlyRepeatType !== "every"}
                                  menuIsOpen
                                />
                                <ReactSelect
                                  className={`periodSelect`}
                                  classNamePrefix="periodSelect"
                                  isSearchable={false}
                                  name={"selectDay"}
                                  value={values?.monthlyWeekDay}
                                  options={weekDays}
                                  onChange={(action: any) => {
                                    setFieldValue(`monthlyWeekDay`, action)
                                  }}
                                  placeholder={""}
                                  components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                  }}
                                  isDisabled={values.monthlyRepeatType !== "every"}
                                  menuIsOpen
                                />
                              </div>
                            </div>
                            <div className={styles.multiPicker__recyclinRow}>
                              <Checkbox
                                name={"monthlyRepeat"}
                                value={"specificDay"}
                                className={styles.checkbox}
                                checked={values.monthlyRepeatType === "specificDay"}
                                onChange={(e: any) => {
                                  setFieldValue("monthlyRepeatType", "specificDay")
                                }}
                                text={t("Конкретный день")}
                                isRadio
                              />
                              <CheckBoxRow
                                name={"monthlyRepeat"}
                                value={"specificDay"}
                                className={styles.checkbox}
                                checked={values.monthlyRepeatType === "specificDay"}
                                onChange={(e: any) => {
                                  setFieldValue("monthlyRepeatType", "specificDay")
                                }}
                                text={t("Конкретный день")}
                                isRadio
                              />
                              <div className={styles.multiPicker__recyclinRowSelects}>
                                <ReactSelect
                                  className={`periodSelect`}
                                  classNamePrefix="periodSelect"
                                  isSearchable={false}
                                  name={"selectCond"}
                                  value={values?.monthlyDaySingle}
                                  options={daysSelect}
                                  onChange={(action: any) => {
                                    setFieldValue(`monthlyDaySingle`, action)
                                  }}
                                  placeholder={""}
                                  components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                  }}
                                  isDisabled={values.monthlyRepeatType !== "specificDay"}
                                  menuIsOpen
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      <div className={styles.multiPicker__recyclinEnd}>
                        <div className={styles.multiPicker__rowLabel}>{t("ending")}</div>
                        <div className={styles.multiPicker__recyclinBlock}>
                          <div className={styles.multiPicker__recyclinRow}>
                            <CheckBoxRow
                              name={"endType"}
                              value={"never"}
                              className={styles.checkbox}
                              checked={values.endType === "never"}
                              onChange={(e: any) => {
                                setFieldValue("endType", "never")
                              }}
                              // text={t("Конкретный день")}
                              // isRadio
                            />
                          </div>
                        </div>
                        <div className={styles.multiPicker__recyclinRow}>
                          <CheckBoxRow
                            name={"endType"}
                            value={"date"}
                            className={styles.checkbox}
                            checked={values.endType === "date"}
                            onChange={(e: any) => {
                              setFieldValue("endType", "date")
                            }}
                          />

                          <input
                            value={values?.endDate ? moment(values?.endDate * 1000).format("DD.MM.YYYY") : ""}
                            onChange={(vl: any) => {
                              setFieldValue("endDate", vl)
                            }}
                            // customClear={() => setFieldValue("endDate", "")}
                            // isClear
                            onClick={() => setFieldValue("selectEndDate", true)}
                            disabled={values.endType !== "date"}
                            placeholder={t("filters.dateEnd")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={styles.multiPicker__pickerBottom}>
                    <div className={styles.multiPicker__pickerBottomLeft}>
                      {withTime && (
                        <button
                          type="button"
                          onClick={() => setFieldValue("selectTime", !values?.selectTime)}
                          className={clsx(`${values?.selectTime ? styles.btnActive : ""}`, styles["open-time-btn"])}
                        >
                          <TimeLightIcon />
                        </button>
                      )}

                      {/* <button
                        type="button"
                        onClick={() => setFieldValue("selectRecyclin", !values?.selectRecyclin)}
                        className={`${values?.selectRecyclin ? styles.btnActive : ""}`}
                      >
                        {`getFontIcon("recycling-icon")`}
                      </button> */}
                    </div>
                    {/* <button type="button" className={} onClick={clearPicker}>
                      {t("clear")}
                    </button> */}
                    <div className={clsx(styles["picker__btn-container"], "picker__btn-container")}>
                      {/* <Button txt={t("reset")} className={`${styles.btn} `} onClick={clearPicker} />
                      <Button txt={t("apply")} className={`${styles.btn} `} onClick={clearPicker} /> */}
                      {/* <Button txt={t("reset")} mode={"gray"} className={styles.btn} onClick={clearPicker} />
                      <Button txt={t("apply")} className={styles.btn} onClick={changeMainDate} /> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.multiPicker__pickerAddition}>
                  <div className={styles.multiPicker__additionHead}>
                    <button
                      type="button"
                      className={`btn btn--boredrerMenu`}
                      onClick={() => setFieldValue("selectEndDate", !values?.selectEndDate)}
                    >
                      {t("back")}
                    </button>
                    {t("filters.dateEnd")}
                  </div>
                  <ReactDatePicker
                    id={"orderpickerEnd"}
                    locale={i18n.language}
                    selected={values?.endDate ? new Date(moment(values?.endDate * 1000).format("YYYY-MM-DD")) : null}
                    onChange={(date: any) => {
                      setFieldValue("endDate", moment(date).unix())
                      setFieldValue("selectEndDate", !values?.selectEndDate)
                    }}
                    inline
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MultiDatePicker
