import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { addAllMessage, selectChat, setAllMessage } from "../../../redux/slice/chat"
import { useDispatch } from "react-redux"
import Chat from "../../Chat/Chat"
import { useAppSelector } from "../../../hooks"
import clsx from "clsx"
import { getApiUrl } from "../../../utils/getApiUrl"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "./OrderCallbackModal"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import styles from "./OrderInfoModal.module.scss"
import useWindowSize from "../../../hooks/useWindowSize"
import TitleBack from "../../TitleBack/TitleBack"
import RatingModal from "../RatingModal/RatingModal"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import { useLazyGetReviewsByOrderIDQuery } from "../../../redux/api/review"
import { selectUser } from "../../../redux/slice/auth"
import { IChatInfo, IChatMessage, ILastMessage } from "../../../types/chat"
import MessagesModalLoader from "../MessagesModal/MessagesModalLoader/MessagesModalLoader"
import { useNavigate } from "react-router-dom"
import CoordinationFooter from "../../CoordinationFooter/CoordinationFooter"
import { IApprovalAssign } from "../../../types/orderTypes"

interface Props {
  id: string
  chatID: string
  coordinationId?: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  delFromList?: (orderId: string) => void
  layout?: "history" | "active" | "coordination"
  modalSize?: "sm" | "lg"
  closeModalFunc?: () => void
}

const OrderInfoModal: FC<Props> = ({
  id,
  chatID,
  coordinationId,
  open,
  setOpen,
  delFromList = () => null,
  layout,
  modalSize,
  closeModalFunc,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const isFirstLoadMess = useRef<any>(false)
  const { allowScroll } = useScrollBlock()
  const { isDesktop } = useWindowSize()
  const user = useAppSelector(selectUser)
  const { allMessage } = useAppSelector(selectChat)
  const hash = location.hash
  const navigate = useNavigate()

  // Количество непрочитанных сообщений
  const countMessageNoRead = allMessage?.filter((ms: any) => !ms?.is_readed)?.length

  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  const [getReviewsByOrderID, { data: orderReview }] = useLazyGetReviewsByOrderIDQuery()
  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [openChat, setOpenChat] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)
  const [dummyChats, setDummyChats] = useState<boolean>(false)
  const [isViewCoordinationFooter, setIsViewCoordinationFooter] = useState<boolean>(false)
  const isFirstRender = useRef(true)

  const closeModal = () => {
    dispatch(setMobileMenuIsHidden(false))
    setOpen(false)
    allowScroll(true)
    if (closeModalFunc) {
      closeModalFunc()
    }
  }

  useEffect(() => {
    if (typeof window.Echo === "undefined" || !chatID) return
    const channel = window.Echo.join(`chats.${chatID}`)
    channel.listen(".client-addMessage", (event: any) => {
      dispatch(addAllMessage(event))
    })

    const channelPrivate = window.Echo.private(`privateUser.${user?.id}`)

    channelPrivate?.listen(".newMessageInDialog", (event: IChatInfo) => {
      const isExistMessage = allMessage.some((mes: IChatMessage) => mes.id === event?.last_message?.id)
      if (isExistMessage) return
      //TODO: после добавления в last_message photos/attachments и т.д., убрать any
      dispatch(addAllMessage(event.last_message as any))
    })

    channelPrivate?.listen(".changeMessageInDialog", (event: ILastMessage) => {
      dispatch(
        setAllMessage(
          allMessage.map((item: any) => {
            if (item.id === event.id) item = { ...item, ...event }
            return item
          }),
        ),
      )
    })
    channelPrivate?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      dispatch(setAllMessage(allMessage.filter((item: any) => item.id !== event.id)))
    })

    return () => {
      if (allMessage?.length) {
        window.Echo.leave(`chats.${chatID}`)
        channelPrivate.stopListening(`.newMessageInDialog`)
        channelPrivate.stopListening(`.changeMessageInDialog`)
        channelPrivate.stopListening(`.deleteMessageInDialog`)
      }
    }
  }, [open, chatID, user?.id, allMessage])

  const getOrderFunc = (id: string) => {
    if (!id) return
    getServiceOrder(id)
      .then((resp) => {
        setLoading(false)
        if (resp?.data?.chat_dialog_id) {
          getChatMessages(resp.data.chat_dialog_id)
            .then((res: any) => {
              isFirstLoadMess.current = true
              dispatch(setAllMessage(res.data.aMessages))
              setLoadingChat(false)
            })
            .catch((er) => console.log(er.message))
        } else {
          setDummyChats(true)
        }
      })
      .catch((err) => console.warn(err))
    isFirstRender.current = false
    getReviewsByOrderID(id)
  }

  useEffect(() => {
    if (!id) return
    getOrderFunc(id)
  }, [id])

  useEffect(() => {
    if (!isDesktop) {
      setOpenChat(hash.includes("#openChat"))
    }
  }, [hash])

  useEffect(() => {
    const assignees: IApprovalAssign[] = []
    orderInfo?.approvals.map((el) => {
      return el.assignees.map((qq) => assignees.push(qq))
    })

    const isHaveProgress = !!assignees?.find((approval: IApprovalAssign) => {
      return approval.concordant.id === user.id && approval.status === "in_progress"
    })

    setIsViewCoordinationFooter(isHaveProgress)
  }, [orderInfo, user])

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={() => {
          setOpen(!open)
          if (closeModalFunc) {
            closeModalFunc()
          }
        }}
        className={clsx(layout && !isDesktop && styles["active-or-history-modal"], {
          [styles["opened-mobile-chat"]]: openChat && !isDesktop,
        })}
        header={
          <>
            {isDesktop ? (
              <>
                {!loading && orderInfo?.photos?.[0] && (
                  <img
                    className={styles.img}
                    src={getApiUrl() + "/public/photo/preview/" + orderInfo.photos[0]}
                    alt=""
                  />
                )}

                <h2 className={clsx("modal__title", styles.title, loading && "skeletonBlock")}>
                  {orderInfo?.name} {orderInfo?.count && orderInfo.count > 1 ? <span>x{orderInfo.count}</span> : ""}
                </h2>
              </>
            ) : (
              <>
                {loading ? (
                  <h2 className={clsx(styles.title, loading && "skeletonBlock")} />
                ) : (
                  <TitleBack
                    title={`${orderInfo?.name}
                      ${orderInfo?.count && orderInfo.count > 1 ? `x${orderInfo.count}` : ""}`}
                    onClick={() => {
                      if (openChat) {
                        setOpenChat(false)
                        navigate(-1)
                      } else {
                        closeModal()
                      }
                    }}
                    noLinkBack
                  />
                )}
              </>
            )}
          </>
        }
        isCloseBtn={isDesktop}
        bodyNoPadding
        size={modalSize}
      >
        <div className={styles.main}>
          <aside className={clsx(styles.aside, layout === "coordination" && styles["aside-lg"])}>
            {orderInfo && !openChat && (
              <OrderDescription isLoading={loading} order={orderInfo} review={orderReview} layout={layout} />
            )}

            <footer className={clsx(styles.footer, layout === "coordination" && styles["footer__painted"])}>
              {isDesktop ? (
                <>
                  {layout === "active" && (
                    <Button
                      //TODO: нет реализации возвращения в работу
                      // txt={layout === "active" ? t("cancel") : t("returnToWork")}
                      txt={t("cancel")}
                      mode={"warning"}
                      size={"sm"}
                      onClick={() => {
                        setCanceledModal(true)
                      }}
                    />
                  )}

                  {layout === "coordination" && isViewCoordinationFooter && (
                    <CoordinationFooter
                      coordinationId={coordinationId}
                      setIsViewCoordinationFooter={setIsViewCoordinationFooter}
                      getOrderFunc={() => getOrderFunc(id)}
                    />
                  )}
                </>
              ) : (
                <div className={`${styles["btn-container"]}`}>
                  {layout === "coordination" && isViewCoordinationFooter && (
                    <CoordinationFooter
                      coordinationId={coordinationId}
                      setIsViewCoordinationFooter={setIsViewCoordinationFooter}
                      getOrderFunc={() => getOrderFunc(id)}
                    />
                  )}
                  {(layout === "active" || layout === "history") && (
                    <div className={`${styles["btn-container__topTwoBtns"]}`}>
                      {layout === "active" && (
                        <Button
                          //TODO: нет реализации возвращения в работу
                          // txt={layout === "active" ? t("cancel") : t("returnToWork")}
                          txt={t("cancel")}
                          mode={"warning"}
                          size={"sm"}
                          onClick={() => {
                            setCanceledModal(true)
                          }}
                        />
                      )}
                      {layout === "history" && orderInfo?.status === 1 && (!orderReview || !orderReview.length) && (
                        <Button
                          txt={t("evaluate")}
                          size={"sm"}
                          onClick={() => {
                            setIsRatingModal(true)
                          }}
                        />
                      )}
                    </div>
                  )}

                  <div className={`${styles["btn-container__bottomTwoBtns"]}`}>
                    <Button
                      txt={t("back")}
                      type="button"
                      onClick={() => {
                        closeModal()
                      }}
                      className={`btn btn_grey ${styles["back-btn"]}`}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setOpenChat(true)
                        navigate(`${hash}#openChat`)
                      }}
                      className={`btn btn_grey ${styles["messanger-btn"]}`}
                    >
                      <img src="/img/chat.svg" alt="" />
                      {t("messages")}
                      {!!countMessageNoRead && (
                        <span className={`${styles.ordersLine__ordersNoReview}`}>{countMessageNoRead}</span>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </footer>
          </aside>
          {(isDesktop || openChat) && (
            <>
              {dummyChats ? (
                <div className={styles.dummyChat} />
              ) : loadingChat ? (
                <MessagesModalLoader />
              ) : (
                <Chat
                  chatID={chatID}
                  orderInfo={orderInfo}
                  isFirstLoadMess={isFirstLoadMess.current}
                  className={`${!isDesktop && styles["mobile-chat"]}`}
                  emptyTxt={t("emptyTxtChatOrder")}
                />
              )}
            </>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && orderInfo?.id && (
        <OrderCallbackModal
          layout={layout}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={orderInfo.id}
          delFromList={delFromList}
          callbackClose={() => {
            closeModal()
          }}
          className={!isDesktop ? "order-info-callback-modal" : ""}
        />
      )}
      {layout === "history" && orderInfo?.id && (
        <RatingModal orderId={orderInfo?.id} open={isRatingModal} setOpen={setIsRatingModal} />
      )}
    </>
  )
}

export default OrderInfoModal
